

































































import { Vue, Component, Prop } from "vue-property-decorator";
import { VoucherInfoProperties } from './VoucherInfoView.vue';
import { AlertDialogProperties } from '@/components/shared/AlertDialogView.vue';
import AutoNumeric, { Options } from 'autonumeric';
import AutoNumericUtil from "@/utilities/AutoNumericUtil";

@Component({})
export default class VoucherMainView extends Vue {
    @Prop() private properties: VoucherInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private inputDiscountAmount: HTMLInputElement;
    private inputDiscountPercent: HTMLInputElement;
    private inputDiscountMaximum: HTMLInputElement;
    private inputMinimumOrderAmount: HTMLInputElement;
    private inputMaximumOrderAmount: HTMLInputElement;

    public get voucher() {
        return this.properties.voucher;
    }

    public get targetOptions() {
        return [
            {
                text: this.$t("text.discount-overall"),
                value: "Overall",
            },
            {
                text: this.$t("text.subtotal"),
                value: "Subtotal",
            },
            {
                text: this.$t("text.shipping-fee"),
                value: "Shipping-Fee",
            },
        ];
    }


    public mounted() {
        this.inputDiscountAmount = this.$el.querySelector("#discount-amount");
        this.inputDiscountPercent = this.$el.querySelector("#discount-percent");
        this.inputDiscountMaximum = this.$el.querySelector("#discount-maximum");
        this.inputMinimumOrderAmount = this.$el.querySelector("#minimum-order-amount");
        this.inputMaximumOrderAmount = this.$el.querySelector("#maximum-order-amount");

        this.updateFormatter();
        this.properties.events.subscribe('update-formatter', this.updateFormatter);
    }

    public destroyed() {
        this.properties.events.remove('update-formatter', this.updateFormatter);
    }

    public updateFormatter() {
        setTimeout(() => {
            const voucher = this.properties.voucher;
            const options: Options = {
                minimumValue: "0",
                historySize: 0,
                outputFormat: "number",
                digitGroupSeparator: "",
                unformatOnHover: false,
                unformatOnSubmit: false,
            };

            AutoNumericUtil.findOrNew(this.inputDiscountAmount, null, options)
                .set(voucher.discountAmount ?? 0);
            AutoNumericUtil.findOrNew(this.inputDiscountPercent, null, options)
                .set(voucher.discountPercent ?? 0);
            AutoNumericUtil.findOrNew(this.inputDiscountMaximum, null, options)
                .set(voucher.discountMaximum ?? 0);
            AutoNumericUtil.findOrNew(this.inputMinimumOrderAmount, null, options)
                .set(voucher.minimumOrderAmount ?? 0);
            AutoNumericUtil.findOrNew(this.inputMaximumOrderAmount, null, options)
                .set(voucher.maximumOrderAmount ?? 0);
        }, 150);
    }
}
